import { SEWebsiteSearch } from "@data-products-and-ai/react-components";
import { useLocation } from "react-router-dom";

const PressWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const areaToSearch = queryParams.get("areaToSearch") as string | undefined;
  const showSearch = queryParams.get("showSearch");
  const showSearchButton = queryParams.get("showSearchButton");
  const predefinedSearch = queryParams.get("predefinedSearch") as
    | string
    | undefined;

  return (
    <>
      <SEWebsiteSearch
        areaToSearch={areaToSearch === "website" ? "website" : "press"}
        showSearch={showSearch === "false" ? false : true}
        showSearchButton={showSearchButton === "false" ? false : true}
        predefinedSearch={predefinedSearch}
      />
    </>
  );
};

export default PressWrapper;
