import { QRCode } from "@data-products-and-ai/react-components";
import { useLocation } from "react-router-dom";

const QRCodeWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const value = queryParams.get("value") as string;
  const size = queryParams.get("size") ?? "128";
  const bgColor = queryParams.get("bgcolor") ?? "FFFFFF";
  const fgColor = queryParams.get("fgcolor") ?? "000000";
  const showLogo = queryParams.get("showlogo") ?? "false";
  const showLogoGlow = queryParams.get("showlogoglow") ?? "false";

  return (
    <>
      {value ? (
        <div style={{ width: parseInt(size), backgroundColor: `#${bgColor}` }}>
          <QRCode
            value={value}
            bgColor={`#${bgColor}`}
            fgColor={`#${fgColor}`}
            size={parseInt(size)}
            showLogo={showLogo !== "true" ? false : true}
            showLogoGlow={showLogoGlow !== "true" ? false : true}
          />
        </div>
      ) : null}
    </>
  );
};

export default QRCodeWrapper;
