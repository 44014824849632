import { LatexToEquation } from "@data-products-and-ai/react-components";
import { useLocation } from "react-router-dom";

const LatexWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const expression = queryParams.get("expression") as string;
  const padding = queryParams.get("padding") ?? "0";
  const fontsize = queryParams.get("fontsize") ?? "14";
  const fontcolor = queryParams.get("fontcolor") ?? "000000";
  const backgroundcolor = queryParams.get("backgroundcolor") ?? "FFFFFF";

  let align = queryParams.get("align");
  align =
    align === "center" || align === "left" || align === "right"
      ? align
      : "center";

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: parseInt(padding),
          backgroundColor: `#${backgroundcolor}`,
          color: `#${fontcolor}`,
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          pointerEvents: "none",
          justifyContent: align as "center" | "left" | "right",
        }}
      >
        {expression ? (
          <LatexToEquation
            expression={expression}
            fontSize={parseInt(fontsize)}
          />
        ) : null}
      </div>
    </>
  );
};

export default LatexWrapper;
