import { Header } from "@data-products-and-ai/react-components";
import { useLocation } from "react-router-dom";

/* type HeaderSize = "small" | "medium" | "large" | undefined; */
const HeaderWrapper = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  /*   const backgroundQuery = queryParams.get("background"); */
  /* 
  const size = queryParams.get("size") as HeaderSize;
  const template = queryParams.get("template"); */
  const title = queryParams.get("title") as string | undefined;
  const subtitle = queryParams.get("subtitle") as string | undefined;

  let decodedSubtitle = "";
  if (subtitle) {
    decodedSubtitle = decodeURIComponent(subtitle);
    decodedSubtitle = decodedSubtitle.replace(/\n/g, "%0A");
  }
  //
  return (
    <>
      <Header title={title} subtitle={decodedSubtitle} template="tableau" />
    </>
  );
};

export default HeaderWrapper;
